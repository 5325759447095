import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Header,
  Segment,
  Form,
  Grid,
  Message,
  Icon,
  Button,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import STORAGE_TYPES from './storageTypes';
import THIRD_PARTY_TYPES from './thirdPartyTypes';
import PublicDatasetsField from './PublicDatasetsField';
import BytesToMinimumGB from '../../../util/BytesToMinimumGB';
import JOB_TYPES from '../types';

class DataFields extends Component {
  state = {
    expanded:
      !this.props.edit &&
      [JOB_TYPES.TRAINING.value, JOB_TYPES.INFERENCE.value].includes(
        this.props.type
      ),
    input_uri_error: false,
    output_uri_error: false,
    input_endpoint_error: false,
    output_endpoint_error: false,
    interval_error: false,
    dataset_errors:
      this.props.initialValues && this.props.initialValues.datasets
        ? new Array(this.props.initialValues.datasets.length).fill(false)
        : [],
    dataset_count:
      this.props.initialValues && this.props.initialValues.datasets
        ? this.props.initialValues.datasets.length
        : 0,
    values: this.props.initialValues || {
      input_uri: '',
      output_uri: '',
      datasets: [],
    },
  };

  componentDidMount() {
    this.props.onChange('data', this.state.values, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEqual(prevState, this.state)) {
      return;
    }
    const datasets = this.state.values.datasets
      .map((dataset) => (!dataset.type ? undefined : dataset))
      .filter((i) => i);

    const dataset_error = new Set(this.state.dataset_errors);
    this.props.onChange(
      'data',
      { ...this.state.values, datasets },
      this.state.input_uri_error ||
        this.state.output_uri_error ||
        this.state.input_endpoint_error ||
        this.state.output_endpoint_error ||
        dataset_error.has(true)
    );
  }

  buildKeyOptions = (options, configured_credentials) => {
    configured_credentials = configured_credentials || [];
    return options.map((option) => {
      const { alwaysEnabled, uriTest, uriExample, feature_level, ...rest } =
        option;
      if (
        configured_credentials.some((key) => key.type === option.key) ||
        alwaysEnabled ||
        this.props.user.feature_level >= feature_level
      ) {
        return rest;
      }
      return { disabled: true, ...rest };
    });
  };

  buildDatasetList = (datasets) => {
    let datasetList = [];
    for (let dataset of datasets) {
      if (dataset.status === 'ready') {
        datasetList.push({
          key: dataset.name,
          text: dataset.name,
          value: dataset.dataset_uuid,
        });
      }
    }
    return datasetList;
  };

  datasetTypeOptions = (type) => {
    const options = [
      { key: 'public', value: 'public', text: 'Public Dataset' },
      { key: 'existing', value: 'existing', text: 'My Dataset' },
    ];
    return options;
  };

  getDataset = (dataset_uuid) => {
    let dataset = this.props.datasets.find(
      (dataset) => dataset.dataset_uuid === dataset_uuid
    );
    if (!dataset) {
      dataset = this.props.publicDatasets.find(
        (dataset) => dataset.dataset_uuid === dataset_uuid
      );
    }
    return dataset;
  };

  datasetErrorCheck = (datasets) => {
    const names = datasets.map((dataset) => {
      if (dataset.name) {
        return dataset.name.toLowerCase().replace(' ', '_');
      }
      return undefined;
    });
    const dup_names = names.filter((e, i, a) => a.indexOf(e) !== i);
    const errors = datasets.map((dataset) => {
      if (dataset.name) {
        return dup_names.includes(dataset.name.toLowerCase().replace(' ', '_'));
      }
      return true;
    });
    return errors;
  };

  handleChange = (e, { name, value }) => {
    const array_re = /(?<key>.+)\[(?<number>[0-9]+)\]\.?(?<attribute>.+)?/;
    const result = name.match(array_re);
    if (result && result.groups.attribute) {
      const array = [...this.state.values[result.groups.key]];
      const item = { ...array[result.groups.number] };
      item[result.groups.attribute] = value;
      if (result.groups.attribute === 'type') {
        delete item.dataset_uuid;
        delete item.name;
        delete item.size;
      } else {
        const dataset = this.getDataset(value);
        item.name = dataset.name;
        item.size = dataset.used_size || dataset.size;
      }
      array[result.groups.number] = item;
      const dataset_errors = this.datasetErrorCheck(array);
      this.setState({
        dataset_errors,
        values: {
          ...this.state.values,
          [result.groups.key]: array,
        },
      });
    } else {
      switch (name) {
        case 'output_type':
          if (!value) {
            // storage type was cleared
            this.setState({
              values: {
                ...this.state.values,
                [name]: value,
                output_uri: '',
                output_options: undefined,
              },
              output_uri_error: false,
              pristine: false,
            });
          } else {
            const uriTest = Object.values(THIRD_PARTY_TYPES).find(
              (type) => type.value === value
            ).uriTest;
            const valid = uriTest.test(this.state.values.output_uri);
            this.setState({
              values: {
                ...this.state.values,
                [name]: value,
                output_options: undefined,
              },
              output_uri_error: Boolean(this.state.values.output_uri) && !valid,
              pristine: false,
            });
          }
          break;
        case 'output_uri': {
          if (
            [
              THIRD_PARTY_TYPES.REGIONAL.value,
              THIRD_PARTY_TYPES.TRAINML.value,
            ].includes(this.state.values.output_type)
          ) {
            this.setState({
              values: {
                ...this.state.values,
                [name]: value,
              },
              output_uri_error: false,
              pristine: false,
            });
          } else {
            const uriTest = Object.values(THIRD_PARTY_TYPES).find(
              (type) => type.value === this.state.values.output_type
            ).uriTest;
            const valid = uriTest.test(value);
            this.setState({
              values: {
                ...this.state.values,
                [name]: value,
              },
              output_uri_error: !valid,
              pristine: false,
            });
          }
          break;
        }
        case 'output_endpoint_url': {
          const valid = /^https:\/\//.test(value);
          this.setState({
            values: {
              ...this.state.values,
              output_options: {
                endpoint_url: value,
              },
            },
            output_endpoint_error: !valid,
            pristine: false,
          });

          break;
        }
        case 'output_path': {
          const valid = THIRD_PARTY_TYPES.REGIONAL.uriTest.test(value);
          this.setState({
            values: {
              ...this.state.values,
              output_options: {
                path: value,
              },
            },
            output_uri_error: !valid,
            pristine: false,
          });
          break;
        }
        case 'interval': {
          const valid =
            value === '' ||
            (!isNaN(parseInt(value)) && parseInt(value) % 15 === 0);
          this.setState({
            values: {
              ...this.state.values,
              output_options: {
                interval: isNaN(parseInt(value)) ? '' : parseInt(value),
              },
            },
            interval_error: !valid,
            pristine: false,
          });
          break;
        }
        case 'input_type':
          if (!value) {
            // storage type was cleared
            this.setState({
              values: {
                ...this.state.values,
                [name]: value,
                input_uri: '',
                input_options: undefined,
              },
              input_uri_error: false,
              pristine: false,
            });
          } else {
            const uriTest = Object.values(THIRD_PARTY_TYPES).find(
              (type) => type.value === value
            ).uriTest;
            const valid = uriTest.test(this.state.values.input_uri);
            this.setState({
              values: {
                ...this.state.values,
                [name]: value,
                input_options: undefined,
              },
              input_uri_error: Boolean(this.state.values.input_uri) && !valid,
              pristine: false,
            });
          }
          break;
        case 'input_uri': {
          if (
            this.state.values.input_type === THIRD_PARTY_TYPES.REGIONAL.value
          ) {
            this.setState({
              values: {
                ...this.state.values,
                [name]: value,
              },
              input_uri_error: false,
              pristine: false,
            });
          } else {
            const uriTest = Object.values(THIRD_PARTY_TYPES).find(
              (type) => type.value === this.state.values.input_type
            ).uriTest;
            const valid = uriTest.test(value);
            this.setState({
              values: {
                ...this.state.values,
                [name]: value,
              },
              input_uri_error: !valid,
              pristine: false,
            });
          }
          break;
        }
        case 'input_endpoint_url': {
          const valid = /^https:\/\//.test(value);
          this.setState({
            values: {
              ...this.state.values,
              input_options: {
                endpoint_url: value,
              },
            },
            input_endpoint_error: !valid,
            pristine: false,
          });

          break;
        }
        case 'input_path': {
          const valid = THIRD_PARTY_TYPES.REGIONAL.uriTest.test(value);
          this.setState({
            values: {
              ...this.state.values,
              input_options: {
                path: value,
              },
            },
            input_uri_error: !valid,
            pristine: false,
          });
          break;
        }
        default:
          this.setState({
            values: { ...this.state.values, [name]: value },
          });
      }
    }
  };

  toggleOpen = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  addDataset = (e) => {
    e.preventDefault();
    this.setState({
      dataset_count: this.state.dataset_count + 1,
      dataset_errors: [...this.state.dataset_errors, false],
      values: {
        ...this.state.values,
        datasets: [...this.state.values.datasets, {}],
      },
    });
  };

  removeDataset = (i) => {
    const datasets = [...this.state.values.datasets];
    datasets.splice(i, 1);
    const dataset_errors = this.datasetErrorCheck(datasets);
    this.setState({
      dataset_errors,
      dataset_count: this.state.dataset_count - 1,
      values: {
        ...this.state.values,
        datasets,
      },
    });
  };

  render() {
    const { type, edit, user } = this.props;
    const {
      expanded,
      dataset_errors,
      dataset_count,
      values,
      output_uri_error,
      input_uri_error,
      input_endpoint_error,
      output_endpoint_error,
      interval_error,
    } = this.state;

    console.log(this.state.values.datasets);
    return (
      <>
        <Message attached onClick={this.toggleOpen}>
          <Message.Header>
            <Icon name={expanded ? 'triangle down' : 'triangle right'} />
            Data{' '}
            {!edit && type === JOB_TYPES.NOTEBOOK.value ? '(Optional)' : ''}
          </Message.Header>
        </Message>
        {expanded ? (
          <>
            <Segment attached>
              <Grid>
                {[JOB_TYPES.TRAINING.value, JOB_TYPES.INFERENCE.value].includes(
                  type
                ) ? (
                  <Grid.Row>
                    <Grid.Column>
                      <Header dividing as='h4' textAlign='left'>
                        Input Data
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                ) : undefined}
                {type === JOB_TYPES.INFERENCE.value ? (
                  <Grid.Row>
                    <Grid.Column width={3}>
                      <Form.Dropdown
                        name='input_type'
                        label='Input Type'
                        options={this.buildKeyOptions(
                          STORAGE_TYPES.input,
                          this.props.projectCredentials
                        )}
                        value={values.input_type}
                        fluid
                        selection
                        clearable
                        disabled={edit}
                        onChange={this.handleChange}
                      />
                    </Grid.Column>
                    {values.input_type === THIRD_PARTY_TYPES.REGIONAL.value ? (
                      <>
                        <Grid.Column width={5}>
                          <Form.Dropdown
                            name='input_uri'
                            label='Datastore'
                            options={this.props.projectDatastores.map(
                              (datastore) => {
                                return {
                                  value: datastore.id,
                                  key: datastore.id,
                                  text: datastore.name,
                                };
                              }
                            )}
                            value={values.input_uri}
                            fluid
                            selection
                            clearable
                            disabled={edit}
                            onChange={this.handleChange}
                          />
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Form.Input
                            name='input_path'
                            label='Datastore path'
                            value={
                              values.input_options
                                ? values.input_options.path
                                : ''
                            }
                            fluid
                            onChange={this.handleChange}
                            disabled={
                              !values.input_type || !values.input_uri || edit
                            }
                            error={
                              input_uri_error
                                ? {
                                    content: 'Datastore Path Invalid',
                                    pointing: 'above',
                                  }
                                : undefined
                            }
                          />
                        </Grid.Column>
                      </>
                    ) : values.input_type === THIRD_PARTY_TYPES.WASABI.value ? (
                      <>
                        <Grid.Column width={7}>
                          <Form.Input
                            name='input_uri'
                            label='Input storage path'
                            value={values.input_uri}
                            fluid
                            onChange={this.handleChange}
                            disabled={!values.input_type || edit}
                            placeholder={
                              values.input_type
                                ? Object.values(THIRD_PARTY_TYPES).find(
                                    (type) => type.value === values.input_type
                                  ).uriExample
                                : ''
                            }
                            error={
                              input_uri_error
                                ? {
                                    content: 'Storage Path Invalid',
                                    pointing: 'above',
                                  }
                                : undefined
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={6}>
                          <Form.Input
                            name='input_endpoint_url'
                            label='Endpoint'
                            value={
                              values.input_options
                                ? values.input_options.endpoint_url
                                : ''
                            }
                            fluid
                            onChange={this.handleChange}
                            disabled={!values.input_type || edit}
                            placeholder='https://s3.wasabisys.com'
                            error={
                              input_endpoint_error
                                ? {
                                    content: 'Endpoint Invalid',
                                    pointing: 'above',
                                  }
                                : undefined
                            }
                          />
                        </Grid.Column>
                      </>
                    ) : (
                      <Grid.Column width={13}>
                        <Form.Input
                          name='input_uri'
                          label='Input storage path'
                          value={values.input_uri}
                          fluid
                          onChange={this.handleChange}
                          disabled={!values.input_type || edit}
                          placeholder={
                            values.input_type
                              ? Object.values(THIRD_PARTY_TYPES).find(
                                  (type) => type.value === values.input_type
                                ).uriExample
                              : ''
                          }
                          error={
                            input_uri_error
                              ? {
                                  content: 'Storage Path Invalid',
                                  pointing: 'above',
                                }
                              : undefined
                          }
                        />
                      </Grid.Column>
                    )}
                  </Grid.Row>
                ) : (
                  <>
                    {dataset_count > 0 ? (
                      <Grid.Row key='header'>
                        <Grid.Column width={4}>
                          <div className='field'>
                            <label>Dataset Type</label>
                          </div>
                        </Grid.Column>
                        <Grid.Column width={9}>
                          <div className='field'>
                            <label>Dataset</label>
                          </div>
                        </Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={2}>
                          <div className='field'>
                            <label>Size (GB)</label>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    ) : undefined}
                    {new Array(dataset_count).fill().map((e, i) => {
                      return (
                        <Grid.Row key={`dataset_${i}`}>
                          <Grid.Column width={4}>
                            <Form.Dropdown
                              name={`datasets[${i}].type`}
                              onChange={this.handleChange}
                              selection
                              fluid
                              value={values.datasets[i].type}
                              options={this.datasetTypeOptions(type)}
                            />
                          </Grid.Column>
                          <Grid.Column width={9}>
                            {values.datasets[i].type === 'public' ? (
                              <Form.Field>
                                <PublicDatasetsField
                                  datasets={this.props.publicDatasets || []}
                                  handleChange={this.handleChange}
                                  value={values.datasets[i].dataset_uuid}
                                  fieldNum={i}
                                  error={dataset_errors[i]}
                                />
                              </Form.Field>
                            ) : (
                              <Form.Dropdown
                                name={`datasets[${i}].dataset_uuid`}
                                onChange={this.handleChange}
                                options={this.buildDatasetList(
                                  this.props.datasets || []
                                )}
                                value={values.datasets[i].dataset_uuid}
                                selection
                                fluid
                                placeholder={'None'}
                                error={
                                  dataset_errors[i] &&
                                  values.datasets[i].dataset_uuid
                                    ? {
                                        content: 'Duplicate Dataset Name',
                                        pointing: 'above',
                                      }
                                    : undefined
                                }
                              />
                            )}
                          </Grid.Column>

                          <Grid.Column
                            width={1}
                            verticalAlign={
                              dataset_errors[i] &&
                              values.datasets[i].dataset_uuid
                                ? 'middle'
                                : 'bottom'
                            }
                          >
                            <Button
                              icon='close'
                              basic
                              onClick={(e) => {
                                e.preventDefault();
                                this.removeDataset(i);
                              }}
                            ></Button>
                          </Grid.Column>
                          {this.state.values.datasets[i].dataset_uuid ? (
                            <Grid.Column width={2} verticalAlign='middle'>
                              <Header as='h5'>
                                {BytesToMinimumGB(
                                  this.state.values.datasets[i].used_size ||
                                    this.state.values.datasets[i].size ||
                                    0
                                )}{' '}
                                GB
                              </Header>
                            </Grid.Column>
                          ) : undefined}
                        </Grid.Row>
                      );
                    })}
                    <Grid.Row>
                      <Grid.Column>
                        <Button
                          icon
                          basic
                          color='black'
                          labelPosition='right'
                          onClick={this.addDataset}
                        >
                          <Icon name='plus' />
                          Add Dataset
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}

                {[JOB_TYPES.TRAINING.value, JOB_TYPES.INFERENCE.value].includes(
                  type
                ) ||
                (JOB_TYPES.NOTEBOOK.value === type &&
                  user.feature_level >= 2) ? (
                  <>
                    <Grid.Row>
                      <Grid.Column>
                        <Header dividing as='h4' textAlign='left'>
                          Output Data
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={3}>
                        <Form.Dropdown
                          name='output_type'
                          label='Output Type'
                          options={this.buildKeyOptions(
                            JOB_TYPES.NOTEBOOK.value === type
                              ? [THIRD_PARTY_TYPES.REGIONAL]
                              : STORAGE_TYPES.output,
                            this.props.projectCredentials
                          )}
                          value={values.output_type}
                          fluid
                          selection
                          clearable
                          disabled={edit && JOB_TYPES.NOTEBOOK.value !== type}
                          onChange={this.handleChange}
                        />
                      </Grid.Column>
                      {values.output_type ===
                      THIRD_PARTY_TYPES.REGIONAL.value ? (
                        <>
                          <Grid.Column width={5}>
                            <Form.Dropdown
                              name='output_uri'
                              label='Datastore'
                              options={this.props.projectDatastores.map(
                                (datastore) => {
                                  return {
                                    value: datastore.id,
                                    key: datastore.id,
                                    text: datastore.name,
                                  };
                                }
                              )}
                              value={values.output_uri}
                              fluid
                              selection
                              clearable
                              disabled={
                                edit && JOB_TYPES.NOTEBOOK.value !== type
                              }
                              onChange={this.handleChange}
                            />
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <Form.Input
                              name='output_path'
                              label='Datastore path'
                              value={
                                values.output_options
                                  ? values.output_options.path
                                  : ''
                              }
                              fluid
                              onChange={this.handleChange}
                              disabled={
                                !values.output_type ||
                                !values.output_uri ||
                                (edit && JOB_TYPES.NOTEBOOK.value !== type)
                              }
                              error={
                                output_uri_error
                                  ? {
                                      content: 'Datastore Path Invalid',
                                      pointing: 'above',
                                    }
                                  : undefined
                              }
                            />
                          </Grid.Column>
                        </>
                      ) : values.output_type ===
                        THIRD_PARTY_TYPES.WASABI.value ? (
                        <>
                          <Grid.Column width={7}>
                            <Form.Input
                              name='output_uri'
                              label='Output storage path'
                              value={values.output_uri}
                              fluid
                              onChange={this.handleChange}
                              disabled={!values.output_type || edit}
                              placeholder={
                                values.output_type
                                  ? Object.values(THIRD_PARTY_TYPES).find(
                                      (type) =>
                                        type.value === values.output_type
                                    ).uriExample
                                  : ''
                              }
                              error={
                                output_uri_error
                                  ? {
                                      content: 'Storage Path Invalid',
                                      pointing: 'above',
                                    }
                                  : undefined
                              }
                            />
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <Form.Input
                              name='output_endpoint_url'
                              label='Endpoint'
                              value={
                                values.output_options
                                  ? values.output_options.endpoint_url
                                  : ''
                              }
                              fluid
                              onChange={this.handleChange}
                              disabled={!values.output_type || edit}
                              placeholder='https://s3.wasabisys.com'
                              error={
                                output_endpoint_error
                                  ? {
                                      content: 'Endpoint Invalid',
                                      pointing: 'above',
                                    }
                                  : undefined
                              }
                            />
                          </Grid.Column>
                        </>
                      ) : values.output_type !==
                        THIRD_PARTY_TYPES.TRAINML.value ? (
                        <Grid.Column width={13}>
                          <Form.Input
                            name='output_uri'
                            label='Output storage path'
                            value={values.output_uri}
                            fluid
                            onChange={this.handleChange}
                            disabled={!values.output_type || edit}
                            placeholder={
                              values.output_type
                                ? Object.values(THIRD_PARTY_TYPES).find(
                                    (type) => type.value === values.output_type
                                  ).uriExample
                                : ''
                            }
                            error={
                              output_uri_error
                                ? {
                                    content: 'Storage Path Invalid',
                                    pointing: 'above',
                                  }
                                : undefined
                            }
                          />
                        </Grid.Column>
                      ) : (
                        <>
                          <Grid.Column width={6}>
                            <Form.Dropdown
                              name='output_uri'
                              label='Entity Type'
                              options={[
                                {
                                  value: 'model',
                                  key: 'model',
                                  text: 'Model',
                                },
                                {
                                  value: 'checkpoint',
                                  key: 'checkpoint',
                                  text: 'Checkpoint',
                                },
                                {
                                  value: 'dataset',
                                  key: 'dataset',
                                  text: 'Dataset',
                                },
                              ]}
                              value={values.output_uri}
                              fluid
                              selection
                              disabled={edit}
                              onChange={this.handleChange}
                            />
                          </Grid.Column>
                          {values.output_uri === 'checkpoint' ? (
                            <Grid.Column width={3}>
                              <Form.Input
                                name='interval'
                                label='Interval (min)'
                                value={
                                  values.output_options
                                    ? values.output_options.interval
                                    : ''
                                }
                                fluid
                                onChange={this.handleChange}
                                disabled={!values.output_type || edit}
                                placeholder={'60'}
                                error={
                                  interval_error
                                    ? {
                                        content:
                                          'Checkpoint Interval Must Be Multiple of 15',
                                        pointing: 'above',
                                      }
                                    : undefined
                                }
                              />
                            </Grid.Column>
                          ) : undefined}
                        </>
                      )}
                    </Grid.Row>
                  </>
                ) : undefined}
              </Grid>
            </Segment>
            {type === JOB_TYPES.TRAINING.value ? (
              <Message attached='bottom' info>
                <Icon name='help' />
                Storage Type Disabled?{' '}
                <Link to='/account/settings' target='_blank'>
                  Add Third Party Keys Here
                </Link>
              </Message>
            ) : undefined}
          </>
        ) : undefined}
      </>
    );
  }
}

function mapStateToProps({
  user,
  projectCredentials,
  datasets,
  publicDatasets,
  projectDatastores,
}) {
  return {
    user,
    projectCredentials,
    datasets,
    publicDatasets,
    projectDatastores: projectDatastores || [],
  };
}

export default connect(mapStateToProps)(DataFields);
