import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  Typography,
  CircularProgress,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { deleteService, fetchServices } from '../../../../actions/services';

import ServiceGridToolbar from './ServiceGridToolbar';
import PollService from './PollService';
import STATUSES from '../../../statuses';
import SERVICE_TYPES from '../ServiceForm/serviceTypes';

export default function Services({ region }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const services = useSelector((state) => state.services);
  const [transitioningServices, setTransitioningServices] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    service_id: false,
  });

  useEffect(() => {
    (async () => {
      if (services === null) {
        dispatch(fetchServices(region.provider_uuid, region.region_uuid));
        return;
      }
    })();
  }, [dispatch, region, services]);

  const editService = useCallback(
    (service) => () => {
      history.push({
        pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/service/edit`,
        search: `?service=${service.service_id}`,
      });
    },
    [history, region]
  );
  const removeService = useCallback(
    (service) => async () => {
      setTransitioningServices((prevTransitioningServices) => [
        ...prevTransitioningServices,
        service.service_id,
      ]);
      await dispatch(
        deleteService(
          region.provider_uuid,
          region.region_uuid,
          service.service_id
        )
      );
      setTransitioningServices((prevTransitioningServices) =>
        prevTransitioningServices.filter((id) => id !== service.service_id)
      );
    },
    [dispatch, region]
  );

  const renderStatus = useCallback(
    (params) => {
      if (transitioningServices.includes(params.id)) {
        return <CircularProgress size={20} />;
      } else {
        switch (params.row.status) {
          case STATUSES.ACTIVE:
            return (
              <Stack direction='row' spacing={1}>
                <CheckCircleIcon color='success' />
              </Stack>
            );
          case STATUSES.EXPIRED:
            return (
              <Stack direction='row' spacing={1} alignItems='center'>
                <ErrorOutlineIcon color='error' />
                <Typography variant='body2'>{params.row.status}</Typography>
              </Stack>
            );
          default:
            return (
              <>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <CircularProgress size={20} />
                  <Typography variant='body2'>{params.row.status}</Typography>
                </Stack>
                <PollService
                  region={region}
                  service={params.row}
                  interval={30000}
                />
              </>
            );
        }
      }
    },
    [transitioningServices, region]
  );

  const renderJob = useCallback((params) => {
    return (
      <Typography variant='body' paragraph>
        {params.row.job_uuid}
      </Typography>
    );
  }, []);

  const renderPort = useCallback((params) => {
    return (
      <Typography variant='body' paragraph>
        {params.row.type === SERVICE_TYPES.HTTPS
          ? 'tcp/443'
          : `${params.row.type}/${params.row.port}`}
      </Typography>
    );
  }, []);

  const renderHostname = useCallback((params) => {
    return (
      <Typography variant='body' paragraph>
        {params.row.custom_hostname || params.row.hostname}
      </Typography>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'service_id',
        headerName: 'ID',
        type: 'string',
        flex: 1.75,
      },
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: renderStatus,
        flex: 0.3,
      },

      {
        field: 'hostname',
        headerName: 'Hostname',
        renderCell: renderHostname,
        flex: 1,
      },
      {
        field: 'port',
        headerName: 'Port',
        renderCell: renderPort,
        flex: 0.4,
      },
      {
        field: 'job',
        headerName: 'Active Job',
        renderCell: renderJob,
        flex: 1,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            onClick={editService(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Remove'
            onClick={removeService(params.row)}
            showInMenu
          />,
        ],
      },
    ],
    [
      editService,
      removeService,
      renderStatus,
      renderJob,
      renderHostname,
      renderPort,
    ]
  );

  const Toolbar = () => (
    <ServiceGridToolbar
      region={region}
      disabled={!user.feature_level || user.feature_level < 2}
    />
  );

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='h6' paragraph gutterBottom>
          Services
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                autoHeight
                checkboxSelection
                getRowId={(row) => row.service_id}
                columns={columns}
                rows={services || []}
                components={{
                  Toolbar: Toolbar,
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
