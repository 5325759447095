import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  Typography,
  CircularProgress,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  deleteDatastore,
  fetchDatastores,
} from '../../../../actions/datastores';

import DatastoreGridToolbar from './DatastoreGridToolbar';

export default function Datastores({ region }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const datastores = useSelector((state) => state.datastores);
  const [transitioningDatastores, setTransitioningDatastores] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    store_id: false,
  });

  useEffect(() => {
    (async () => {
      if (datastores === null) {
        dispatch(fetchDatastores(region.provider_uuid, region.region_uuid));
        return;
      }
    })();
  }, [dispatch, region, datastores]);

  const editDatastore = useCallback(
    (datastore) => () => {
      history.push({
        pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/datastore/edit`,
        search: `?datastore=${datastore.store_id}`,
      });
    },
    [history, region]
  );
  const removeDatastore = useCallback(
    (datastore) => async () => {
      setTransitioningDatastores((prevTransitioningDatastores) => [
        ...prevTransitioningDatastores,
        datastore.store_id,
      ]);
      await dispatch(
        deleteDatastore(
          region.provider_uuid,
          region.region_uuid,
          datastore.store_id
        )
      );
      setTransitioningDatastores((prevTransitioningDatastores) =>
        prevTransitioningDatastores.filter((id) => id !== datastore.store_id)
      );
    },
    [dispatch, region]
  );

  const renderStatus = useCallback(
    (params) => {
      if (transitioningDatastores.includes(params.id)) {
        return <CircularProgress size={20} />;
      } else {
        return (
          <Stack direction='row' spacing={1}>
            <CheckCircleIcon color='success' />
          </Stack>
        );
      }
    },
    [transitioningDatastores]
  );

  const columns = useMemo(
    () => [
      {
        field: 'store_id',
        headerName: 'ID',
        type: 'string',
        flex: 1.25,
      },
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: renderStatus,
        flex: 0.25,
      },
      {
        field: 'type',
        headerName: 'Type',
        type: 'string',
        flex: 0.5,
      },
      {
        field: 'uri',
        headerName: 'URI/Address',
        type: 'string',
        flex: 0.5,
      },
      {
        field: 'root',
        headerName: 'Root Path',
        type: 'string',
        flex: 1,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            onClick={editDatastore(params.row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Remove'
            onClick={removeDatastore(params.row)}
            showInMenu
          />,
        ],
      },
    ],
    [editDatastore, removeDatastore, renderStatus]
  );

  const Toolbar = () => (
    <DatastoreGridToolbar
      region={region}
      disabled={!user.feature_level || user.feature_level < 2}
    />
  );

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='h6' paragraph gutterBottom>
          Datastores
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                autoHeight
                checkboxSelection
                getRowId={(row) => row.store_id}
                columns={columns}
                rows={datastores || []}
                components={{
                  Toolbar: Toolbar,
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
