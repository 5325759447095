import React from 'react';

import { FormControl, Box, FormHelperText, TextField } from '@mui/material';

export default function EnvKeyField({
  index,
  value,
  error,
  setValue,
  setError,
}) {
  const handleChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    if (newValue === '') {
      setError('Key cannot be blank');
    } else if (!/^[a-zA-Z_]+[a-zA-Z0-9_]*$/.test(newValue)) {
      setError('Key is not a valid environment variable.');
    } else if (/^(TRAINML|NVIDIA)/.test(newValue)) {
      setError('TRAINML and NVIDIA keys are reserved');
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <FormControl fullWidth required>
        <TextField
          label={`Key ${index + 1}`}
          id={`key-${index + 1}`}
          aria-describedby={`key-${index + 1}-helper-text`}
          variant='outlined'
          onChange={handleChange}
          error={Boolean(error)}
          value={value}
        />
        {error ? (
          <FormHelperText id={`key-${index + 1}-helper-text`}>
            {error}
          </FormHelperText>
        ) : (
          <FormHelperText id={`key-${index + 1}-helper-text`}>
            Enter the environment variable key.
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
