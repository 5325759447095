import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Grid,
  Paper,
  Toolbar,
  Stack,
  IconButton,
  Typography,
  Box,
  Divider,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import theme from 'theme';
import ErrorSnackBar from 'components/shared/ErrorSnackBar';
import {
  fetchDatastores,
  createDatastore,
  updateDatastore,
} from 'actions/datastores';
import DatastoreNameField from './DatastoreNameField';
import DatastoreTypeField from './DatastoreTypeField';
import DatastoreUriField from './DatastoreUriField';
import DatastoreRootField from './DatastoreRootField';
import DATASTORE_TYPES from 'components/shared/datastoreTypes';
import DatastoreUsernameField from './DatastoreUsernameField';
import DatastorePasswordField from './DatastorePasswordField';
import { clearErrorMessage } from 'actions/errorMessage';
import { fetchActiveRegion } from 'actions/regions';
import LoadingMask from 'components/shared/LoadingMask';

export default function DatastoreForm() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { provider_uuid, region_uuid } = useParams();

  const api_error = useSelector((state) => state.errorMessage);
  const datastores = useSelector((state) => state.datastores);
  const user = useSelector((state) => state.user);

  const sp = new URLSearchParams(location.search);

  const region = useSelector((state) => state.region);
  const [loading_region, setLoadingRegion] = useState(region === null);

  useEffect(() => {
    (async () => {
      if (region === null) {
        await dispatch(fetchActiveRegion(provider_uuid, region_uuid));
        setLoadingRegion(false);
      }
    })();
  }, [dispatch, region, provider_uuid, region_uuid, setLoadingRegion]);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (region && datastores === null) {
        dispatch(fetchDatastores(region.provider_uuid, region.region_uuid));
        return;
      }
    })();
  }, [dispatch, region, datastores]);

  let datastore;
  if (datastores && sp.get('datastore')) {
    datastore = datastores.find(
      (datastore) => datastore.store_id === sp.get('datastore')
    );
  }
  const [pristine, setPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(datastore ? datastore.name : undefined);
  const [name_error, setNameError] = useState(false);
  const [type, setType] = useState(datastore ? datastore.type : '');
  const [type_error, setTypeError] = useState(false);
  const [uri, setUri] = useState(datastore ? datastore.uri : undefined);
  const [uri_error, setUriError] = useState(false);
  const [root, setRoot] = useState(datastore ? datastore.root : undefined);
  const [root_error, setRootError] = useState(false);
  const [username, setUsername] = useState(
    datastore ? datastore.username : undefined
  );
  const [username_error, setUsernameError] = useState(false);
  const [password, setPassword] = useState(
    datastore ? datastore.password : undefined
  );
  const [password_error, setPasswordError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (datastore) {
        await dispatch(
          updateDatastore(
            region.provider_uuid,
            region.region_uuid,
            datastore.store_id,
            {
              name,
              uri,
              root,
              username,
              password,
            }
          )
        );
      } else {
        await dispatch(
          createDatastore(region.provider_uuid, region.region_uuid, {
            name,
            type,
            uri,
            root,
            username,
            password,
          })
        );
      }

      history.push({
        pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/dashboard`,
      });
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading_region) {
    return (
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <Container>
            <LoadingMask />
          </Container>
        </ScopedCssBaseline>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Paper sx={{ display: 'flex', width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
                  <Toolbar>
                    <Stack spacing={2} direction='row' alignItems='center'>
                      <IconButton
                        onClick={() => history.goBack()}
                        disabled={loading}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography variant='h5'>
                        {datastore
                          ? `Edit ${datastore.name}`
                          : `Create New ${region?.name} Datastore`}
                      </Typography>
                    </Stack>
                  </Toolbar>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  padding='15px'
                  sx={{
                    maxWidth: 400,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <DatastoreNameField
                        value={name}
                        error={name_error}
                        setValue={setName}
                        setError={setNameError}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <DatastoreTypeField
                        provider_type={region?.provider_type}
                        value={type}
                        error={type_error}
                        setValue={setType}
                        setError={setTypeError}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    {[DATASTORE_TYPES.SMB, DATASTORE_TYPES.NFS].includes(
                      type
                    ) ? (
                      <>
                        <Grid item xs={12} md={12} lg={12}>
                          <DatastoreUriField
                            value={uri}
                            type={type}
                            error={uri_error}
                            setValue={setUri}
                            setError={setUriError}
                            setChanged={() => setPristine(false)}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <DatastoreRootField
                            value={root}
                            error={root_error}
                            setValue={setRoot}
                            setError={setRootError}
                            setChanged={() => setPristine(false)}
                          />
                        </Grid>
                      </>
                    ) : undefined}
                    {[DATASTORE_TYPES.SMB].includes(type) ? (
                      <>
                        <Grid item xs={12} md={12} lg={12}>
                          <DatastoreUsernameField
                            value={username}
                            error={username_error}
                            setValue={setUsername}
                            setError={setUsernameError}
                            setChanged={() => setPristine(false)}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <DatastorePasswordField
                            value={password}
                            error={password_error}
                            setValue={setPassword}
                            setError={setPasswordError}
                            setChanged={() => setPristine(false)}
                          />
                        </Grid>
                      </>
                    ) : undefined}
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Divider />
                <Toolbar>
                  <Stack spacing={2} direction='row'>
                    <LoadingButton
                      variant='contained'
                      color='brand'
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={
                        pristine ||
                        Boolean(name_error) ||
                        Boolean(type_error) ||
                        Boolean(uri_error) ||
                        Boolean(root_error) ||
                        Boolean(username_error) ||
                        Boolean(password_error) ||
                        !Boolean(name) ||
                        !Boolean(type) ||
                        ([DATASTORE_TYPES.SMB, DATASTORE_TYPES.NFS].includes(
                          type
                        ) &&
                          !Boolean(uri)) ||
                        ([DATASTORE_TYPES.SMB, DATASTORE_TYPES.NFS].includes(
                          type
                        ) &&
                          !Boolean(root)) ||
                        user.feature_level < 2
                      }
                    >
                      Submit
                    </LoadingButton>

                    <Button
                      variant='outlined'
                      onClick={() => history.goBack()}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Toolbar>
              </Grid>
            </Grid>
          </Paper>
          <ErrorSnackBar message={api_error} />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
