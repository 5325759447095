import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  Select,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import STORAGE_TYPES from '../../jobs/JobForm/storageTypes';
import renderThirdPartyMenuOption from '../../shared/renderThirdPartyMenuOption';
import { fetchCredentials } from '../../../actions/projectCredentials';

export default function CollectionSourceTypeField({
  entity_type,
  value,
  error,
  setValue,
  setChanged,
  edit,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const project_credentials = useSelector((state) => state.projectCredentials);

  useEffect(() => {
    (async () => {
      if (project_credentials === null) {
        dispatch(fetchCredentials());
        return;
      }
    })();
  }, [dispatch, project_credentials]);

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    if (newValue !== value) {
      setChanged();
    }
    setValue(newValue);
  };
  const project_key_types = project_credentials
    ? project_credentials.map((key) => key.type)
    : [];

  return (
    <Box>
      <FormControl fullWidth required>
        <InputLabel id='source-type-label'>Source Type</InputLabel>
        <Select
          labelId='source-type-label'
          id='source_type'
          value={value}
          label='Source Type'
          aria-describedby='source-type-helper-text'
          onChange={handleChange}
          disabled={edit}
          error={Boolean(error)}
        >
          {STORAGE_TYPES[entity_type].map((type) =>
            renderThirdPartyMenuOption(
              type,
              project_key_types,
              user.feature_level
            )
          )}
        </Select>
        {error ? (
          <FormHelperText id='source-type-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='source-type-helper-text'>
            Select the source type to load the {entity_type} from. Configure
            available source types by{' '}
            <Link
              to={
                user.id === user.selected_project
                  ? '/account/settings'
                  : `/project/details/${user.selected_project}`
              }
              target='_blank'
            >
              adding third-party credentials.
            </Link>
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
