import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Grid,
  Paper,
  Toolbar,
  Stack,
  IconButton,
  Typography,
  Box,
  Divider,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import theme from '../../../../theme';
import ErrorSnackBar from '../../../shared/ErrorSnackBar';
import {
  fetchDataConnectors,
  createDataConnector,
  updateDataConnector,
} from '../../../../actions/dataConnectors';
import DataConnectorNameField from './DataConnectorNameField';
import DataConnectorTypeField from './DataConnectorTypeField';

import DATA_CONNECTOR_TYPES from './dataConnectorTypes';

import { clearErrorMessage } from '../../../../actions/errorMessage';
import { fetchActiveRegion } from '../../../../actions/regions';
import LoadingMask from '../../../shared/LoadingMask';
import DataConnectorProtocolField from './DataConnectorProtocolField';
import DataConnectorPortRangeField from './DataConnectorPortRangeField';
import DataConnectorCidrField from './DataConnectorCidrField';

export default function DatastoreForm() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { provider_uuid, region_uuid } = useParams();

  const api_error = useSelector((state) => state.errorMessage);
  const data_connectors = useSelector((state) => state.dataConnectors);
  const user = useSelector((state) => state.user);

  const sp = new URLSearchParams(location.search);

  const region = useSelector((state) => state.region);
  const [loading_region, setLoadingRegion] = useState(region === null);

  useEffect(() => {
    (async () => {
      if (region === null) {
        await dispatch(fetchActiveRegion(provider_uuid, region_uuid));
        setLoadingRegion(false);
      }
    })();
  }, [dispatch, region, provider_uuid, region_uuid, setLoadingRegion]);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (region && data_connectors === null) {
        dispatch(fetchDataConnectors(region.provider_uuid, region.region_uuid));
        return;
      }
    })();
  }, [dispatch, region, data_connectors]);

  let data_connector;
  if (data_connectors && sp.get('data_connector')) {
    data_connector = data_connectors.find(
      (data_connector) =>
        data_connector.connector_id === sp.get('data_connector')
    );
  }
  const [pristine, setPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(data_connector?.name || undefined);
  const [name_error, setNameError] = useState(false);
  const [type, setType] = useState(data_connector?.type || '');
  const [type_error, setTypeError] = useState(false);
  const [protocol, setProtocol] = useState(data_connector?.protocol || '');
  const [port_range, setPortRange] = useState(data_connector?.port_range || '');
  const [port_range_error, setPortRangeError] = useState(false);
  const [cidr, setCidr] = useState(data_connector?.cidr || '');
  const [cidr_error, setCidrError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = Object.fromEntries(
      Object.entries({
        name,
        type,
        protocol,
        port_range,
        cidr,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );
    try {
      if (data_connector) {
        await dispatch(
          updateDataConnector(
            region.provider_uuid,
            region.region_uuid,
            data_connector.connector_id,
            payload
          )
        );
      } else {
        await dispatch(
          createDataConnector(region.provider_uuid, region.region_uuid, payload)
        );
      }

      history.push({
        pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/dashboard`,
      });
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading_region) {
    return (
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <Container>
            <LoadingMask />
          </Container>
        </ScopedCssBaseline>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Paper sx={{ display: 'flex', width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
                  <Toolbar>
                    <Stack spacing={2} direction='row' alignItems='center'>
                      <IconButton
                        onClick={() => history.goBack()}
                        disabled={loading}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography variant='h5'>
                        {data_connector
                          ? `Edit ${data_connector.name}`
                          : `Create New ${region?.name} Data Connector`}
                      </Typography>
                    </Stack>
                  </Toolbar>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  padding='15px'
                  sx={{
                    maxWidth: 400,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <DataConnectorNameField
                        value={name}
                        error={name_error}
                        setValue={setName}
                        setError={setNameError}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <DataConnectorTypeField
                        value={type}
                        error={type_error}
                        setValue={setType}
                        setError={setTypeError}
                        setChanged={() => setPristine(false)}
                        edit={Boolean(data_connector)}
                      />
                    </Grid>

                    {[DATA_CONNECTOR_TYPES.CUSTOM].includes(type) ? (
                      <>
                        <Grid item xs={12} md={12} lg={12}>
                          <DataConnectorProtocolField
                            value={protocol}
                            setValue={setProtocol}
                            setChanged={() => setPristine(false)}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <DataConnectorPortRangeField
                            value={port_range}
                            error={port_range_error}
                            setValue={setPortRange}
                            setError={setPortRangeError}
                            setChanged={() => setPristine(false)}
                            edit={Boolean(data_connector)}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <DataConnectorCidrField
                            value={cidr}
                            error={cidr_error}
                            setValue={setCidr}
                            setError={setCidrError}
                            setChanged={() => setPristine(false)}
                            edit={Boolean(data_connector)}
                          />
                        </Grid>
                      </>
                    ) : undefined}
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Divider />
                <Toolbar>
                  <Stack spacing={2} direction='row'>
                    <LoadingButton
                      variant='contained'
                      color='brand'
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={
                        pristine ||
                        Boolean(name_error) ||
                        Boolean(type_error) ||
                        Boolean(port_range_error) ||
                        Boolean(cidr_error) ||
                        !Boolean(name) ||
                        !Boolean(type) ||
                        ([DATA_CONNECTOR_TYPES.CUSTOM].includes(type) &&
                          (!Boolean(protocol) ||
                            !Boolean(port_range) ||
                            !Boolean(cidr))) ||
                        user.feature_level < 3
                      }
                    >
                      Submit
                    </LoadingButton>

                    <Button
                      variant='outlined'
                      onClick={() => history.goBack()}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Toolbar>
              </Grid>
            </Grid>
          </Paper>
          <ErrorSnackBar message={api_error} />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
