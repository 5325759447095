import React from 'react';

import { Box, MenuItem, Tooltip } from '@mui/material';

export default function renderThirdPartyMenuOption(
  type,
  project_key_types,
  featureLevel
) {
  const disabled = !(
    type.alwaysEnabled ||
    project_key_types.includes(type.value) ||
    featureLevel >= type.feature_level
  );
  return (
    <MenuItem key={type.value} disabled={disabled} value={type.value}>
      <Tooltip
        followCursor
        placement='right'
        title={Boolean(type.toolTip) ? type.toolTip : ''}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>{type.text}</Box>
      </Tooltip>
    </MenuItem>
  );
}
