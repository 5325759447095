import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Grid,
  Paper,
  Toolbar,
  Stack,
  IconButton,
  Typography,
  Box,
  Divider,
  Button,
  FormControlLabel,
  Switch,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';

import theme from '../../../../theme';
import ErrorSnackBar from '../../../shared/ErrorSnackBar';
import {
  fetchServices,
  createService,
  updateService,
} from '../../../../actions/services';
import ServiceNameField from './ServiceNameField';
import ServiceCustomHostnameField from './ServiceCustomHostnameField';
import { clearErrorMessage } from '../../../../actions/errorMessage';
import { fetchActiveRegion } from '../../../../actions/regions';
import ServicePrivateKeyField from './ServicePrivateKeyField';
import ServiceCertField from './ServiceCertField';
import ServiceTypeField from './ServiceTypeField';
import SERVICE_TYPES from './serviceTypes';
import ServicePortField from './ServicePortField';

export default function ServiceForm() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { provider_uuid, region_uuid } = useParams();

  const api_error = useSelector((state) => state.errorMessage);
  const services = useSelector((state) => state.services);
  const user = useSelector((state) => state.user);

  const sp = new URLSearchParams(location.search);
  const region = useSelector((state) => state.region);

  useEffect(() => {
    if (!region) {
      dispatch(fetchActiveRegion(provider_uuid, region_uuid));
    }
  }, [dispatch, region, provider_uuid, region_uuid]);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (region && services === null) {
        dispatch(fetchServices(region.provider_uuid, region.region_uuid));
        return;
      }
    })();
  }, [dispatch, region, services]);

  let service;
  if (services && sp.get('service')) {
    service = services.find(
      (service) => service.service_id === sp.get('service')
    );
  }
  const [pristine, setPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(service?.name || '');
  const [name_error, setNameError] = useState(false);
  const [type, setType] = useState(service?.type || 'https');

  const [custom_hostname, setCustomHostname] = useState(
    service?.custom_hostname || ''
  );
  const [custom_hostname_error, setCustomHostnameError] = useState(false);
  const [is_public, setIsPublic] = useState(service?.public || false);
  const [manual_certificate, setManualCertificate] = useState(
    service?.manual_certificate || false
  );
  const [cert, setCert] = useState(service?.cert || '');
  const [cert_error, setCertError] = useState(false);
  const [private_key, setPrivateKey] = useState(service?.private_key || '');
  const [private_key_error, setPrivateKeyError] = useState(false);
  const [port, setPort] = useState(service?.port || '');
  const [port_error, setPortError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = Object.fromEntries(
      Object.entries({
        name,
        type,
        custom_hostname,
        public: is_public,
        cert,
        private_key,
        port,
      }).filter(([k, v]) => v !== undefined && v !== '')
    );

    try {
      if (service) {
        await dispatch(
          updateService(
            region.provider_uuid,
            region.region_uuid,
            service.service_id,
            payload
          )
        );
      } else {
        await dispatch(
          createService(region.provider_uuid, region.region_uuid, payload)
        );
      }

      history.push({
        pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/dashboard`,
      });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (is_public && type !== SERVICE_TYPES.HTTPS) {
      setType(SERVICE_TYPES.HTTPS);
    }
    if (type === SERVICE_TYPES.HTTPS && Boolean(port)) {
      setPort('');
      setPortError(false);
    }
  }, [is_public, type, port, setType, setPort, setPortError]);

  if (!region) {
    history.push('/resources');
    return <div>Redirecting...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Paper sx={{ display: 'flex', width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
                  <Toolbar>
                    <Stack spacing={2} direction='row' alignItems='center'>
                      <IconButton
                        onClick={() => history.goBack()}
                        disabled={loading}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography variant='h5'>
                        {service
                          ? `Edit ${service.name}`
                          : `Create New ${region.name} Service`}
                      </Typography>
                    </Stack>
                  </Toolbar>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  padding='15px'
                  sx={{
                    maxWidth: 400,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <ServiceNameField
                        value={name}
                        error={name_error}
                        setValue={setName}
                        setError={setNameError}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={is_public}
                            onChange={() => {
                              setPristine(false);
                              setIsPublic(!is_public);
                            }}
                            disabled={Boolean(service)}
                          />
                        }
                        label='Accessible from Public Internet'
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <ServiceTypeField
                        value={type}
                        setValue={setType}
                        setChanged={() => setPristine(false)}
                        disabled={is_public}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <ServiceCustomHostnameField
                        value={custom_hostname}
                        error={custom_hostname_error}
                        setValue={setCustomHostname}
                        setError={setCustomHostnameError}
                        setChanged={() => setPristine(false)}
                        edit={Boolean(service)}
                      />
                    </Grid>
                    {type !== SERVICE_TYPES.HTTPS ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <ServicePortField
                          value={port}
                          error={port_error}
                          setValue={setPort}
                          setError={setPortError}
                          setChanged={() => setPristine(false)}
                          edit={Boolean(service)}
                        />
                      </Grid>
                    ) : undefined}
                    {custom_hostname ? (
                      <Alert severity='info'>
                        Custom hostname services require the manual
                        configuration of DNS records in the customer DNS server.
                        Review{' '}
                        <Link
                          to={`${process.env.REACT_APP_DOC_URL}/reference/cloudbender#regional-resources`}
                        >
                          service documentation
                        </Link>{' '}
                        for more information
                      </Alert>
                    ) : undefined}
                    {custom_hostname && is_public ? (
                      <Alert severity='warning'>
                        Public services with custom hostnames require manual
                        ingress routing through a customer managed public IP
                        address. Review{' '}
                        <Link
                          to={`${process.env.REACT_APP_DOC_URL}/reference/cloudbender#regional-resources`}
                        >
                          service documentation
                        </Link>{' '}
                        for more information
                      </Alert>
                    ) : undefined}
                    {custom_hostname ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={manual_certificate}
                              onChange={() => {
                                setPristine(false);
                                setManualCertificate(!manual_certificate);
                              }}
                              disabled={Boolean(service)}
                            />
                          }
                          label='Manually Upload Certificate'
                        />
                      </Grid>
                    ) : undefined}
                    {manual_certificate ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <ServiceCertField
                          value={cert}
                          error={cert_error}
                          setValue={setCert}
                          setError={setCertError}
                          setChanged={() => setPristine(false)}
                          edit={Boolean(service)}
                        />
                      </Grid>
                    ) : undefined}
                    {manual_certificate ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <ServicePrivateKeyField
                          value={private_key}
                          error={private_key_error}
                          setValue={setPrivateKey}
                          setError={setPrivateKeyError}
                          setChanged={() => setPristine(false)}
                          edit={Boolean(service)}
                        />
                      </Grid>
                    ) : undefined}
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Divider />
                <Toolbar>
                  <Stack spacing={2} direction='row'>
                    <LoadingButton
                      variant='contained'
                      color='brand'
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={
                        pristine ||
                        Boolean(name_error) ||
                        Boolean(custom_hostname_error) ||
                        Boolean(private_key_error) ||
                        Boolean(cert_error) ||
                        Boolean(port_error) ||
                        !Boolean(name) ||
                        !Boolean(type) ||
                        (Boolean(custom_hostname) &&
                          manual_certificate &&
                          !Boolean(service) &&
                          (!Boolean(cert) || !Boolean(private_key))) ||
                        user.feature_level < 3
                      }
                    >
                      Submit
                    </LoadingButton>

                    <Button
                      variant='outlined'
                      onClick={() => history.goBack()}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Toolbar>
              </Grid>
            </Grid>
          </Paper>
          <ErrorSnackBar message={api_error} />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
