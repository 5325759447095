import React, { useEffect } from 'react';

import {
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProjects } from '../../../actions/projects';

export default function CollectionProjectField({
  value,
  error,
  setValue,
  setChanged,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const projects = useSelector((state) => state.projects);

  useEffect(() => {
    (async () => {
      if (projects === null) {
        dispatch(fetchProjects());
        return;
      }
    })();
  }, [dispatch, projects]);

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    if (newValue !== value) {
      setChanged();
    }
    setValue(newValue);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id='project-label'>
          Destination Project (Optional)
        </InputLabel>
        <Select
          labelId='project-label'
          id='project_uuid'
          value={value}
          label='Destination Project (Optional)'
          aria-describedby='project-label-helper-text'
          onChange={handleChange}
          disabled={!user.feature_level || user.feature_level < 3}
          error={Boolean(error)}
        >
          {projects.map((project) => (
            <MenuItem key={project.id} value={project.id}>
              <Box sx={{ display: 'flex', width: '100%' }}>{project.name}</Box>
            </MenuItem>
          ))}
        </Select>
        {error ? (
          <FormHelperText id='source-type-helper-text'>{error}</FormHelperText>
        ) : (
          <FormHelperText id='source-type-helper-text'>
            Select the project to copy the data to. (Requires Team plan or
            above)
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
