import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Button,
  IconButton,
  Box,
  Typography,
  Grid,
  Toolbar,
  Divider,
  Paper,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import theme from '../../../../../../theme';

import DeviceConfigImageField from './DeviceConfigImageField';
import DeviceConfigModelUuidField from './DeviceConfigModelUuidField';
import DeviceConfigCommandField from './DeviceConfigCommandField';
import {
  createDeviceConfig,
  fetchDeviceConfigs,
  updateDeviceConfig,
} from '../../../../../../actions/deviceConfigs';
import DeviceConfigNameField from './DeviceConfigNameField';
import DeviceConfigDatastoreIdField from './DeviceConfigDatastoreIdField';
import DeviceConfigDatastorePathField from './DeviceConfigDatastorePathField';
import DeviceConfigWorkerKeysField from './DeviceConfigWorkerKeysField';
import ErrorSnackBar from '../../../../../shared/ErrorSnackBar';
import { clearErrorMessage } from '../../../../../../actions/errorMessage';
import DeviceConfigEnvField from './DeviceConfigEnvField';
import DeviceConfigCheckpointField from './DeviceConfigCheckpointField';
import LoadingMask from '../../../../../shared/LoadingMask';
import { fetchActiveRegion } from '../../../../../../actions/regions';

export default function DeviceConfigForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { provider_uuid, region_uuid } = useParams();
  const sp = new URLSearchParams(location.search);
  const config_id = sp.get('config_id');

  const region = useSelector((state) => state.region);
  const [loading_region, setLoadingRegion] = useState(region === null);
  const device_configs = useSelector((state) => state.deviceConfigs);
  const api_error = useSelector((state) => state.errorMessage);

  const device_config = device_configs
    ? device_configs.find(
        (device_config) => device_config.config_id === config_id
      )
    : undefined;

  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [name, setName] = useState(device_config ? device_config.name : '');
  const [model_uuid, setModelUuid] = useState(
    device_config ? device_config.model_uuid : ''
  );
  const [image, setImage] = useState(device_config ? device_config.image : '');
  const [command, setCommand] = useState(
    device_config ? device_config.command : ''
  );
  const [nameError, setNameError] = useState(false);
  const [modelUuidError, setModelUuidError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [commandError, setCommandError] = useState(false);

  const [datastore_id, setDatastoreId] = useState(
    device_config ? device_config.datastore_id : ''
  );
  const [datastore_path, setDatastorePath] = useState(
    device_config ? device_config.datastore_path : ''
  );
  const [datastorePathError, setDatastorePathError] = useState(false);
  const [worker_key_types, setWorkerKeyTypes] = useState(
    device_config?.worker_key_types || []
  );
  const [env, setEnv] = useState(device_config?.env || []);
  const [envError, setEnvError] = useState(false);
  const [checkpoints, setCheckpoints] = useState(
    device_config?.checkpoints || []
  );

  useEffect(() => {
    (async () => {
      if (!region) {
        dispatch(fetchActiveRegion(provider_uuid, region_uuid));
        setLoadingRegion(false);
      }
    })();
  }, [dispatch, region, provider_uuid, region_uuid, setLoadingRegion]);

  useEffect(() => {
    (async () => {
      if (region && device_configs === null) {
        dispatch(fetchDeviceConfigs(region.provider_uuid, region.region_uuid));
        return;
      }
    })();
  }, [dispatch, region, device_configs]);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        name,
        model_uuid,
        model_project_uuid: user.selected_project,
        image,
        command,
        datastore_id,
        datastore_path,
        worker_key_types,
        env,
        checkpoints,
      };
      Object.keys(payload).forEach(
        (k) => (!payload[k] || payload[k] === '') && delete payload[k]
      );

      if (device_config) {
        await dispatch(
          updateDeviceConfig(
            region.provider_uuid,
            region.region_uuid,
            device_config.config_id,
            payload
          )
        );
      } else {
        await dispatch(
          createDeviceConfig(region.provider_uuid, region.region_uuid, payload)
        );
      }

      history.push({
        pathname: `/resources/provider/${region.provider_uuid}/region/${region.region_uuid}/dashboard`,
      });
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading_region || device_configs === null) {
    return (
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <Container>
            <LoadingMask />
          </Container>
        </ScopedCssBaseline>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Paper sx={{ display: 'flex', width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
                  <Toolbar>
                    <Stack spacing={2} direction='row' alignItems='center'>
                      <IconButton
                        onClick={() => history.goBack()}
                        disabled={loading}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography variant='h5'>
                        {device_config
                          ? `Edit ${device_config.name}`
                          : `Create New ${region.name} Device Configuration`}
                      </Typography>
                    </Stack>
                  </Toolbar>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  padding='15px'
                  sx={{
                    maxWidth: 400,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <DeviceConfigNameField
                        value={name}
                        error={nameError}
                        setValue={setName}
                        setError={setNameError}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <DeviceConfigImageField
                        value={image}
                        error={imageError}
                        setValue={setImage}
                        setError={setImageError}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <DeviceConfigModelUuidField
                        value={model_uuid}
                        error={modelUuidError}
                        setValue={setModelUuid}
                        setError={setModelUuidError}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <DeviceConfigCheckpointField
                        value={checkpoints}
                        setValue={setCheckpoints}
                        setChanged={() => setPristine(false)}
                      />
                      <br />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <DeviceConfigCommandField
                        value={command}
                        error={commandError}
                        setValue={setCommand}
                        setError={setCommandError}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <DeviceConfigDatastoreIdField
                        value={datastore_id}
                        setValue={setDatastoreId}
                        setChanged={() => setPristine(false)}
                        region={region}
                      />
                    </Grid>
                    {Boolean(datastore_id) ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <DeviceConfigDatastorePathField
                          value={datastore_path}
                          error={datastorePathError}
                          setValue={setDatastorePath}
                          setError={setDatastorePathError}
                          setChanged={() => setPristine(false)}
                        />
                      </Grid>
                    ) : undefined}
                    <Grid item xs={12} md={12} lg={12}>
                      <DeviceConfigEnvField
                        value={env}
                        error={envError}
                        setValue={setEnv}
                        setError={setEnvError}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <DeviceConfigWorkerKeysField
                        value={worker_key_types}
                        setValue={setWorkerKeyTypes}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Divider />
                <Toolbar>
                  <Stack spacing={2} direction='row'>
                    <LoadingButton
                      variant='contained'
                      color='brand'
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={
                        pristine ||
                        Boolean(nameError) ||
                        Boolean(imageError) ||
                        Boolean(modelUuidError) ||
                        Boolean(commandError) ||
                        Boolean(datastorePathError) ||
                        Boolean(envError) ||
                        !Boolean(name) ||
                        !Boolean(image) ||
                        !Boolean(model_uuid) ||
                        !Boolean(command) ||
                        user.feature_level < 2
                      }
                    >
                      Submit
                    </LoadingButton>

                    <Button
                      variant='outlined'
                      onClick={() => history.goBack()}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Toolbar>
              </Grid>
            </Grid>
          </Paper>
          <ErrorSnackBar message={api_error} />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
