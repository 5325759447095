import _ from 'lodash';
import React, { Component } from 'react';

import {
  Header,
  Segment,
  Grid,
  Message,
  Icon,
  Button,
  Checkbox,
  Form,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import JOB_TYPES from '../types';
import EndpointRoute from './EndpointRoute';

class EndpointFields extends Component {
  state = {
    expanded:
      !this.props.edit && [JOB_TYPES.ENDPOINT.value].includes(this.props.type),

    routes_errors: this.props.initialValues
      ? new Array(this.props.initialValues.routes.length).fill({
          duplicate: false,
          error: false,
        })
      : [],
    routes_count: this.props.initialValues
      ? this.props.initialValues.routes.length
      : 0,
    custom_start_command: Boolean(
      this.props.initialValues && this.props.initialValues.start_command
    ),
    values: this.props.initialValues || {
      routes: [],
      start_command: '',
      service_id: '',
    },
  };

  componentDidMount() {
    this.props.onChange(
      'endpoint',
      this.state.values,
      !Boolean(this.state.values.routes.length)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEqual(prevState, this.state)) {
      return;
    }

    const routes = this.state.values.routes
      .map((route) => (!route.path ? undefined : route))
      .filter((i) => i);
    const route_error = new Set(
      _.flatten(this.state.routes_errors.map((error) => Object.values(error)))
    );
    this.props.onChange(
      'endpoint',
      { ...this.state.values, routes },
      (!this.state.custom_start_command &&
        (route_error.has(true) || !Boolean(routes.length))) ||
        (this.state.custom_start_command && !this.state.values.start_command)
    );
  }

  routeDupCheck = (routes, routes_errors) => {
    const paths = routes.map((route) => {
      if (route.path) {
        return route.verb + route.path.toLowerCase();
      }
      return undefined;
    });
    const dup_route = paths.filter((e, i, a) => a.indexOf(e) !== i);
    const errors = routes.map((route, i) => {
      const error = { ...routes_errors[i] };
      if (route.path) {
        error.duplicate = dup_route.includes(
          route.verb + route.path.toLowerCase()
        );
      }
      return error;
    });
    return errors;
  };

  handleChange = (e, { name, value }) => {
    this.setState({
      values: { ...this.state.values, [name]: value },
    });
  };

  handleRouteChange = (index, value, error) => {
    const routes = [...this.state.values.routes];
    routes[index] = value;
    const routes_errors = this.routeDupCheck(routes, [
      ...this.state.routes_errors,
    ]);
    routes_errors[index] = { ...routes_errors[index], error };
    this.setState({
      routes_errors,
      values: {
        ...this.state.values,
        routes,
      },
    });
  };

  toggleCustomStartCommand = () => {
    this.setState((prevState) => ({
      custom_start_command: !prevState.custom_start_command,
      routes_errors: [],
      routes_count: 0,
      values: {
        ...this.state.values,
        custom_start_command: '',
        routes: [],
      },
    }));
  };

  toggleOpen = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  addRoute = (e) => {
    e.preventDefault();
    this.setState({
      routes_count: this.state.routes_count + 1,
      routes_errors: [
        ...this.state.routes_errors,
        {
          duplicate: false,
          error: false,
        },
      ],
      values: {
        ...this.state.values,
        routes: [...this.state.values.routes, {}],
      },
    });
  };

  removeRoute = (i) => {
    const routes = [...this.state.values.routes];
    routes.splice(i, 1);
    const routes_errors = this.routeDupCheck(routes, [
      ...this.state.routes_errors,
    ]);
    this.setState({
      routes_errors,
      routes_count: this.state.routes_count - 1,
      values: {
        ...this.state.values,
        routes,
      },
    });
  };

  render() {
    const { edit } = this.props;
    const {
      expanded,
      routes_errors,
      routes_count,
      custom_start_command,
      values,
    } = this.state;
    return (
      <>
        <Message attached onClick={this.toggleOpen}>
          <Message.Header>
            <Icon name={expanded ? 'triangle down' : 'triangle right'} />
            Endpoint{' '}
          </Message.Header>
        </Message>
        {expanded ? (
          <>
            <Segment attached>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Checkbox
                      name='custom_start_command'
                      checked={custom_start_command}
                      label='Manually Specify Server Command'
                      onClick={this.toggleCustomStartCommand}
                    />
                  </Grid.Column>
                </Grid.Row>
                {custom_start_command ? (
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input
                        name='start_command'
                        label='Start Command'
                        value={values.start_command}
                        disabled={edit}
                        onChange={this.handleChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                ) : (
                  <>
                    <Grid.Row>
                      <Grid.Column>
                        <Header dividing as='h4' textAlign='left'>
                          Routes
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    {new Array(routes_count).fill().map((e, i) => {
                      return (
                        <EndpointRoute
                          key={`route_${i}`}
                          index={i}
                          data={values.routes[i]}
                          duplicate={routes_errors[i].duplicate}
                          onChange={this.handleRouteChange}
                          edit={edit}
                          removeRoute={this.removeRoute}
                        />
                      );
                    })}
                    <Grid.Row>
                      <Grid.Column>
                        <Button
                          icon
                          basic
                          color='black'
                          labelPosition='right'
                          onClick={this.addRoute}
                        >
                          <Icon name='plus' />
                          Add Route
                        </Button>
                      </Grid.Column>
                    </Grid.Row>{' '}
                  </>
                )}
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Form.Dropdown
                      name='service_id'
                      label='Regional Port Service'
                      options={this.props.projectServices.map((service) => {
                        return {
                          value: service.id,
                          key: service.id,
                          text: service.name,
                        };
                      })}
                      value={values.service_id}
                      fluid
                      selection
                      clearable
                      disabled={
                        edit ||
                        !this.props.user.feature_level ||
                        this.props.user.feature_level < 2
                      }
                      onChange={this.handleChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </>
        ) : undefined}
      </>
    );
  }
}

function mapStateToProps({ user, projectServices }) {
  return { user, projectServices: projectServices || [] };
}

export default connect(mapStateToProps)(EndpointFields);
